<template>
  <div class="bg-white p-10">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="10">
          <a-col :md="6" :sm="24">
            <a-form-item label="商品名称">
              <a-input v-model="queryParams.name" placeholder="请输入商品名称" size="small" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="类目">
              <a-cascader
                v-model="queryParams.categoryId"
                :load-data="loadData"
                :options="options"
                change-on-select
                placeholder="请选择类目"
                size="small"
                @change="onChangeCascader"
              />
            </a-form-item>
          </a-col>
<!--          <a-col :md="7" :sm="24">-->
<!--            <a-form-item label="价格">-->
<!--              <a-input-number v-model="queryParams.minPrice" :min="0" size="small" />-->
<!--              - -->
<!--              <a-input-number v-model="queryParams.maxPrice" :min="0" size="small" />-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <a-button class="mr-5" size="small" type="primary" @click="chaxun()">查询</a-button>
          <a-button class="mr-5" size="small" type="info" @click="queryParams = {}">重置</a-button>
        </a-row>
      </a-form>

    </div>

<!--    <iphone-preview v-model="showPreview" :appendBody="true" :scroll="true">-->
<!--      <a-spin :spinning="previewLoading">-->
<!--        <div style="max-width: 100%;padding: 1px">-->
<!--          <img :src="previewData.imgUrl" width="100%">-->
<!--          <div class="p-10">-->
<!--            <Ellipsis :length="100" style="font-size: 16px;font-weight: 600">{{ previewData.name }}</Ellipsis>-->
<!--          </div>-->
<!--          <div v-if="Object.keys(userInfo).length > 0" class="px-10 pb-10" style="color: #ff4723;font-size: 22px">-->
<!--            {{ '￥' + previewData.tradePrice }}-->
<!--          </div>-->
<!--          <h6 v-if="Object.keys(userInfo).length === 0" class="text-danger text-md">价格登录可见</h6>-->
<!--          <div class="previewContent" v-html="previewData.detailHtml">-->
<!--          </div>-->
<!--        </div>-->
<!--      </a-spin>-->
<!--    </iphone-preview>-->

    <a-spin :spinning="loading" tip="Loading...">
      <div v-if="goods.length === 0">
<!--        <span style="margin-left: 40px;">暂无数据</span>-->
      </div>
      <div class="goods">
        <a-card
          v-for="(item, index) in goods"
          :key="index"
          :hoverable="true"
          class="goodsCard"
          @click.stop="onFn(item)"
          >
          <img
            slot="cover"
            :src="item.imgUrl ? item.imgUrl: ''"
            alt="example"
          />
          <a-tooltip :title="item.name" placement="top">
            <a-card-meta :title="item.name">
              <template slot="description">
                <div class="card flex-row flex-row-between">
                  <span v-if="item.tradePrice" style="color: #d11514;font-size: 16px;font-weight: 600;">￥{{ item.tradePrice.toFixed(2) }}</span>
                  <span style="color: #000"><span style="color: rgba(9, 98, 234, 1);font-size: 14px;">{{ item.sale }}+</span> <span style="color: rgba(185, 185, 185, 1);font-size: 12px;">月销</span></span>
                </div>
                <!-- <a-icon :theme="item.supplyProduct.isFavorite === 1 ? 'twoTone' : 'outlined' " class="heart" two-tone-color="#eb2f96" type="heart" @click.stop="handleHeart(item,index)" /> -->
              </template>
            </a-card-meta>
          </a-tooltip>

          <div class="btn">
            <div v-if="!item.put" class="right" @click.stop="handleAddGoods(item, index)">一键入库</div>
            <div v-else class="right">已入库</div>
          </div>
        </a-card>
      </div>
      <div v-if="goods.length !== 0" class="flex-row" style="flex-direction: row-reverse;padding: 10px">
        <a-pagination
          v-model="queryParam.pageNum"
          :page-size="queryParam.pageSize"
          :page-size-options="pageSizeOptions"
          :total="total"
          show-size-changer
          @change="onChange"
          @showSizeChange="onShowSizeChange"
        >
        </a-pagination>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import iphonePreview from '@/components/iphonePreview/iphonePreview'
import {selfQueryCate, selfQueryProductList, selfQueryProduct} from '@/api/HandpickGoods'

/**
 * @author  XuHongli
 * @date  2023/1/5 15:35
 * @version 1.0
 * @description
 */
export default {
  name: 'SupplyFromManufacturer',
  components: {
    iphonePreview
  },
  data() {
    return {
      isBoole: false,
      options: [],
      queryParams: {},
      showPreview:false,
      previewLoading: false,
      previewData:{},
      pageSizeOptions: ['10', '20', '30'],
      goods: [],
      loading: true,
      total: 1,
      queryParam: {
        pageNum: 1,
        pageSize: 30,
        descs: 'update_time',
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
  },
  created() {
    this.getList()
    this.initTree()
  },
  methods: {
    onFn(item) {
      this.loading = true
      selfQueryProduct({id: item.id}).then(res => {
        if (res.data) {
          let PAGE_URL = window.origin + '/commodity/' + res.data
          window.open(PAGE_URL, '_blank')
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        console.error('错误', err)
      })
    },
    onChangeCascader(value) {
      if (value.length == 2) {
        this.isBoole = true
      }else {
        this.isBoole = false
      }
    },
    loadData(selectedOptions) {
      let _this = this
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true

      // load options lazily
      selfQueryCate({cateId: targetOption.id}).then(res => {
        _this.fliterTreeData(res).then((result) => {
          if (result.length) targetOption.children = result
          if (this.isBoole && targetOption.children){
            targetOption.children.forEach(item => {
              item.isLeaf = this.isBoole
            })
          }
          _this.options = [...this.options]
          targetOption.loading = false
        })
      }).catch(err => {
        console.error(err)
        targetOption.loading = false
        _this.$message.error('数据获取失败')
      })
    },
    /** 过滤,编辑数据格式*/
    fliterTreeData(data) {
      return new Promise((resolve, reject) => {
        try {
          for (let i = 0; i < data.length; i++) {
            const item = data[i]
            for (let key in item) {
              item.value = item.id
              item.label = item.name
              item.isLeaf = false
            }
          }
          resolve(data)
        } catch (error) {
          reject(error)
        }
      })
    },
    //格式化数据，递归将空的children置为undefined
    formatData(data) {
      const that = this
      data.forEach((element) => {
        if (element.children && element.children.length > 0) {
          that.formatData(element.children)
        } else {
          element.children = null
        }
      })
      return data
    },
    // 查询
    chaxun() {
      let obj = { categoryId: null }
      if (this.queryParams.categoryId) {
        obj.categoryId = this.queryParams.categoryId[this.queryParams.categoryId.length - 1]
      }
      this.loading = true
      let objInfo = Object.assign({}, this.queryParam, this.queryParams, obj)
      selfQueryProductList(objInfo).then((res) =>{
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
      })
    },
    handleAddGoods(item, index) {
      const that = this
      const ids = [item.id ? item.id : null]
      this.$AddGroupModal({
        ids,
        isHandpickGoods: true,
        handleOk: (val) => {
          if (val === 'ok') {
            that.goods[index].put = true
          }
        },
      })
    },
    getList() {
      this.loading = true
      selfQueryProductList(this.queryParam).then((res) =>{
        console.log('列表', res)
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
      }).catch(err => {
        this.loading = false
      })
    },
    initTree () {
      selfQueryCate({cateId: 0}).then(res => {
        this.fliterTreeData(res).then((result) => {
          this.options = result
        })
      }).catch(err => {
        console.error(err)
        this.$message.error('数据获取失败')
      })
    },
    onChange(page, pageSize){
      let obj = { categoryId: null }
      if (this.queryParams.categoryId) {
        obj.categoryId = this.queryParams.categoryId[this.queryParams.categoryId.length - 1]
      }
      this.queryParam.pageNum = page
      this.queryParam.pageSize = pageSize
      this.loading = true
      let objInfo = Object.assign({}, this.queryParam, this.queryParams, obj)
      selfQueryProductList(objInfo).then((res) => {
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
      })
    },
    onShowSizeChange(pageNum, pageSize) {
      let obj = { categoryId: null }
      if (this.queryParams.categoryId) {
        obj.categoryId = this.queryParams.categoryId[this.queryParams.categoryId.length - 1]
      }
      this.queryParam.pageNum = 1
      this.queryParam.pageSize = pageSize
      this.loading = true
      let objInfo = Object.assign({}, this.queryParam, this.queryParams, obj)
      selfQueryProductList(objInfo).then((res) => {
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.goods{
  padding: 0 20px 20px 0;
  display: flex;
  flex-wrap: wrap;
  .goodsCard {
    position: relative;
    margin: 10px 20px;
    width: 250px;
    height: 370px;
    box-shadow:
      3.4px 3.4px 2.7px rgba(0, 0, 0, 0.016),
      8.7px 8.7px 6.9px rgba(0, 0, 0, 0.025),
      17.7px 17.7px 14.2px rgba(0, 0, 0, 0.03),
      36.5px 36.5px 29.2px rgba(0, 0, 0, 0.034),
      100px 100px 80px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    img{
      width: 238px;
      height: 238px;
      padding: 10px 0 0 10px;
      border-radius: 10px;
      object-fit: cover;
    }

    .btn{
      position: absolute;
      padding: 0 15px;
      width: 250px;
      left: 0;
      right: 0;
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      color: #fff;
      cursor: pointer;
      // border: 1px solid red;
      .left,.right{
        width: 100px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 50px;
        background-color: $color-main;
        color: #fff;
      }
    }
  }
}
</style>
